import { getCookieValue, getGUID, setCookie } from "@/services/HttpClient";

import ActionHandler from "@/handlers/action";
import Cookies from "js-cookie";
import axios from "axios";
import { clickStreamOne } from "./clickStreamAnalyticsBackUp";
import { getBrowserName } from "@/utils/utils";
import store from "@/store/store";
import { v4 as uuidv4 } from "uuid";

// import { http } from "../services/HttpClient";

var ipNum;
var isLongitude;
var isLatitude;
////

function getCeUID() {
  let requestID = getCookieValue("ce_uid") || "";

  if (!requestID) {
    let newRequestId = uuidv4();
    setCookie("ce_uid", newRequestId, 7, false);
    requestID = newRequestId;
  }
  return requestID;
}

function getRequestID() {
  let requestID = getCookieValue("requestId") || "";

  if (!requestID) {
    let newRequestId = getGUID();
    setCookie("requestId", newRequestId, 7, false);
    requestID = newRequestId;
  }
  return requestID;
}

async function generateToken(captcha) {
  return axios.get(
    import.meta.env.VITE_LOGIN_API_BASE_URL + "/login/v3/session-token",
    {
      headers: {
        "Content-Type": "application/json",
        ApplicationName: "KYC-WEB",
        "x-captcha": captcha,
        "x-source": "Spark-WEB",
        "x-sourceid": 3,
        "x-usertype": 1,
        "x-operatingsystem": ActionHandler.checkOS(),
        "x-clientlocalip": localStorage.getItem("myip"),
        "x-clientpublicip": localStorage.getItem("myip"),
        "x-macaddress": getCookieValue("macAddress") || "00:00:5e:00:53:af",
        "x-deviceid":
          getCookieValue("platform") || ActionHandler.fnGenerateUniqueID(),
      },
    }
  );
}

async function getToken() {
  let token = getCookieValue(import.meta.env.VITE_APP_GUEST_COOKIE);
  if (!token) {
    let TK = "";
    try {
      const captcha = await ActionHandler.getCapchaCode();
      const { data } = await generateToken(captcha);
      if (data.status == "success") {
        TK = data?.data?.session_token;
      }
    } catch (error) {
      TK = "";
    }
    return TK;
  }
  return token;
}

const defaultOptions = {
  // baseURL: base.url,
  baseURL: import.meta.env.VITE_CLICKSTREAM_URL_NEW,
  method: "get",
  headers: {
    "Content-Type": "application/json",
    ce_uid: getCeUID(),
    ApplicationName: "Spark-Web",
    "X-Origin": window.location.origin,
    "X-requestId": getRequestID(),
    "x-app-version": import.meta.env.VITE_VERSION,
    "x-build-number": import.meta.env.PACKAGE_VERSION,
  },
};

// const appVersion = packageJson.version;

// let headers = {
//   Accept: "application/json",
//   "Content-Type": "application/json",
//   "Accept-Language": "en-US",
//   "X-appVersion": `${appVersion}|${appVersion}`,
// };

const axios_instance_clickstream = axios.create(defaultOptions);
axios_instance_clickstream.interceptors.request.use(
  async function (config) {
    const token = await getToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios_instance_clickstream.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.message === "Network Error" && !error.response) {
      console.log(error.message);
    }
    if (error) {
      return Promise.reject(error);
    }
  }
);

class ClickStreamAnalytics {
  constructor() {
    this.getIpParameters().then((data) => {
      ipNum = data;
    });
    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(function (position) {
    //     isLongitude = position.coords.latitude.toString();
    //     isLatitude = position.coords.longitude.toString();
    //   });
    // }
  }

  sendClickStreamEvent(eventsToSend) {
    axios_instance_clickstream
      .post("/v1/events/data", this.getRequestParamsData(eventsToSend), {
        headers: {
          "X-event-count": eventsToSend.length,
        },
      })
      .then(() => {
        // this.globalEventList = [];
      })
      .catch(() => {
        console.warn("error while sending clickstream data");
      })
      .finally(() => {
        let localCs = JSON.parse(localStorage.getItem("CLICKSTREAM") || "[]");
        if (localCs.length === eventsToSend.length) {
          localStorage.setItem("CLICKSTREAM", []);
        } else {
          localCs = localCs.slice(eventsToSend.length);
          localStorage.setItem("CLICKSTREAM", [JSON.stringify(localCs)]);
        }
      });
  }

  sendClickStreamMetadata() {
    // axios_instance_clickstream.post()
    let clickStreamMeta = {
      ce_uid: getCeUID(),
      request_timestamp: ClickStreamAnalytics.getFormattedUTCDateTime(
        new Date()
      ), // TimeStamp Format -> YYYY-MM-DD HH:MM:SS.sss
      source_url: window.origin,
      os_version: navigator.appVersion,
      device_manufacturer: navigator.platform,
      device_model: getBrowserName(),
      device_token: "",
      release_version: "analytics2.0",
      build_release: import.meta.env.VITE_BUILD_RELEASE,
      app_version: import.meta.env.PACKAGE_VERSION,
      os_platform: this.getDeviceName(),
      session_id: "",
      extra_fields: "{}",
      app_id: "",
      app_name: "KYC-WEB",
    };
    clickStreamMeta.session_id = this.getSessionID(clickStreamMeta);
    clickStreamMeta.app_id = this.getAppID(clickStreamMeta);
    axios_instance_clickstream
      .post("/v1/events/metadata", clickStreamMeta, {
        timeout: 5000,
      })
      .then((res) => {
        // console.log("res =>", res);
      });
  }

  getRequestParamsData(eventsToSend) {
    let requestParams = {
      ce_uid: getCeUID(),
      request_timestamp: ClickStreamAnalytics.getFormattedUTCDateTime(
        new Date()
      ),
      source_url: window.origin,
      session_id: "",
      user_id: "",
      client_ip: "",
      client_latitude: "",
      client_longitude: "",
      source_metadata: "",
      events: "",
    };

    requestParams.extra_fields = {
      utm_source: this.getUrlParameter("utm_source"),
      utm_medium: this.getUrlParameter("utm_medium"),
      utm_campaign: this.getUrlParameter("utm_campaign"),
      utm_content: this.getUrlParameter("utm_content"),
      utm_metadata: this.getUrlParameter("utm_metadata"),
    };
    requestParams.session_id = this.getSessionID(requestParams); // +
    requestParams.events = eventsToSend; // +
    requestParams.app_id = this.getAppID(requestParams); // +
    requestParams.client_ip = ipNum ? ipNum : "Permission Denied"; // +
    requestParams.user_id = this.getUserId(); //+
    requestParams.client_latitude = isLatitude // +
      ? isLatitude
      : "Permission Denied";
    requestParams.client_longitude = isLongitude //+
      ? isLongitude
      : "Permission Denied";
    //  return JSON.stringify(requestParams);
    return requestParams;
  }
  getUserId() {
    // eslint-disable-next-line
    const userIdBody = `{application_id: ${
      store?.state?.formData?.appNumber
    }, auc: , clevertap_id: , gtm_id: , analytics_kyc_id: , kyc_platform: spark_web, journey_id: ${this.journey_Id()},journey:onboarding}`;
    return userIdBody;
  }
  journey_Id() {
    let UserJourneyType = {
      KRA: "Select_KRA",
      OCR: "Select_OCR",
      DIGILOCKER: "Select_Digilocker",
      MANUAL: "Select_Manual",
    };
    let _Journey = "";
    const oldJourneyBool =
      (store?.state?.formData["isKRA"] == true &&
        store?.state?.formData["isCKYC"] == true) ||
      (store?.state?.formData["isKRACompliant"] == true &&
        store?.state?.formData["isCvlKRA"] == true);
    if (oldJourneyBool && store?.state?.formData["isKRAJourney"]) {
      _Journey = UserJourneyType.KRA;
    } else if (store?.state?.formData["poaType"] == "digilocker") {
      _Journey = UserJourneyType.DIGILOCKER;
    } else if (store?.state?.formData["isPOAOCR"] == true) {
      _Journey = UserJourneyType.OCR;
    } else {
      _Journey = UserJourneyType.MANUAL;
    }
    return _Journey;
  }

  addEventsTolocalStorage(csEvent, forcePush) {
    let clikS = [
      ...JSON.parse(localStorage.getItem("CLICKSTREAM") || "[]"),
      {
        event_timestamp: ClickStreamAnalytics.getFormattedDateTime(new Date()),
        event_id: csEvent?.event_id ? csEvent?.event_id : "",
        screen_id: csEvent?.screen_id ? csEvent?.screen_id : "", // what is screen id, need for this ?
        screen_name: csEvent?.event_screen ? csEvent?.event_screen : "",
        element_id: csEvent?.element_id ? csEvent?.element_id : "", // what is element id, need for this ?
        event_sub_type: csEvent?.event_sub_type ? csEvent?.event_sub_type : "",
        element_name: csEvent?.element_name ? csEvent?.element_name : "", // what is element_name , need for this ?
        action_name: csEvent?.action_name ? csEvent?.action_name : "", // what is action_name , need for this ?
        // "(ButtonClick / ButtonMouseover / LinkClick) what user did action on element. means if he click on button then ButtonClick action if any mouseover like tooptip action then button mouseover",
        referred_from: "",
        event_type: csEvent?.event_type ? csEvent?.event_type : "",
        event_name: csEvent?.event_name ? csEvent?.event_name : "",
        event_source: location.href,
        event_property: csEvent?.event_property ? csEvent?.event_property : [], // what is  event_property ?
        event_metadata: csEvent?.event_metadata
          ? this.metadataBuilder(csEvent)
          : "NA",
        event_location: "",
        server_timestamp: "",
      },
    ];

    localStorage.setItem("CLICKSTREAM", [JSON.stringify(clikS)]);
    let delay = 30;
    this.eventInterval = setInterval(() => {
      this.triggerClickStreamEvents();
    }, delay * 1000);
    if (
      JSON.parse(localStorage.getItem("CLICKSTREAM") || "[]").length == 10 ||
      forcePush
    ) {
      this.triggerClickStreamEvents();
      clearInterval(this.eventInterval);
    }
  }

  // globalEventList = JSON.parse(localStorage.getItem("CLICKSTREAM") || "[]");
  eventInterval;
  addEventList(csEvent, forcePush) {
    clickStreamOne.addEventList(csEvent, forcePush);

    if (import.meta.env.VITE_CLICKSTREAM_NEW_ENABLE == "true") {
      this.addEventsTolocalStorage(csEvent, forcePush);
    }
  }

  triggerClickStreamEvents() {
    const events = JSON.parse(localStorage.getItem("CLICKSTREAM") || "[]");
    if (
      events.length &&
      import.meta.env.VITE_CLICKSTREAM_NEW_ENABLE == "true"
    ) {
      this.sendClickStreamEvent(events);
    }
  }

  async getIpParameters() {
    try {
      let { data } = await axios.get(
        "https://www.cloudflare.com/cdn-cgi/trace"
      );
      let data1 = data
        .trim()
        .split("\n")
        .reduce(function (obj, pair) {
          pair = pair.split("=");
          return (obj[pair[0]] = pair[1]), obj;
        }, {});
      const ipParam = this.IP6to4(data1.ip);
      return ipParam;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }
  IP6to4(ip6) {
    function parseIp6(ip6str) {
      const str = ip6str.toString();
      const ar = new Array();
      for (var i = 0; i < 8; i++) ar[i] = 0;
      if (str == "::") return ar;
      const sar = str.split(":");
      let slen = sar.length;
      if (slen > 8) slen = 8;
      let j = 0;
      i = 0;
      for (i = 0; i < slen; i++) {
        if (i && sar[i] == "") {
          j = 9 - slen + i;
          continue;
        }
        ar[j] = parseInt(`0x0${sar[i]}`);
        j++;
      }
      return ar;
    }
    var ip6parsed = parseIp6(ip6);
    const ip4 = `${ip6parsed[6] >> 8}.${ip6parsed[6] & 0xff}.${
      ip6parsed[7] >> 8
    }.${ip6parsed[7] & 0xff}`;
    return ip4;
  }
  static getFormattedDateTime(dt) {
    var year = dt.getFullYear().toString().padStart(4, "0");
    var month = (dt.getMonth() + 1).toString().padStart(2, "0");
    var date = dt.getDate().toString().padStart(2, "0");
    var hours = dt.getHours().toString().padStart(2, "0");
    var minutes = dt.getMinutes().toString().padStart(2, "0");
    var secs = dt.getSeconds().toString().padStart(2, "0");
    var milliseconds = dt.getMilliseconds().toString().padStart(3, "0");

    // YYYY-MM-DD HH:MM:SS.sss
    return (
      year +
      "-" +
      month +
      "-" +
      date +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      secs +
      ":" +
      milliseconds
    );
  }
  static getFormattedUTCDateTime(item) {
    var dt1 = new Date(item);
    var year = dt1.getUTCFullYear().toString().padStart(4, "0");
    var month = (dt1.getUTCMonth() + 1).toString().padStart(2, "0");
    var date = dt1.getUTCDate().toString().padStart(2, "0");
    var hours = dt1.getUTCHours().toString().padStart(2, "0");
    var minutes = dt1.getUTCMinutes().toString().padStart(2, "0");
    var secs = dt1.getUTCSeconds().toString().padStart(2, "0");
    var milliseconds = dt1.getUTCMilliseconds().toString().padStart(3, "0");

    // YYYY-MM-DD HH:MM:SS.sss
    return (
      year +
      "-" +
      month +
      "-" +
      date +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      secs +
      ":" +
      milliseconds
    );
  }
  getGUID() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  getUrlParameter(name, url) {
    if (!url) url = window.location.href;
    if (!url.toString().includes("utm_source")) url = document.referrer;
    name = name.replace(/[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return "";

    if (!results[2]) return "";

    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }
  getSessionID(ele) {
    var sessionID = "";
    let session_name;
    ele.sparkSessionCookie
      ? (session_name = ele.sparkSessionCookie)
      : (session_name = "kyc_session");
    if (Cookies.get(session_name)) {
      sessionID = Cookies.get(session_name);
    } else {
      sessionID = this.getGUID().replace(/-/g, "");
      Cookies.set(session_name, sessionID, {
        secure: import.meta.env.VITE_ISCUG == "true" ? true : "",
        sameSite: "strict",
      });
    }
    return sessionID;
  }
  getDeviceName() {
    var deviceName = "";
    if (window.matchMedia("(max-width: 767px)").matches) {
      // The viewport is less than 768 pixels wide
      deviceName = "Flutter_Mweb";
    } else if (window.matchMedia("(max-width: 959px)").matches) {
      // The viewport is less than 959 pixels wide
      deviceName = "Flutter_Tweb";
    } else {
      // The viewport is at least 960 pixels wide
      deviceName = "Flutter_Web";
    }
    return deviceName;
  }
  getAppID(ele) {
    var appID = "";
    let cookieName;
    ele.cookieName
      ? (cookieName = ele.cookieName)
      : (cookieName = "kyc_app_id");
    if (Cookies.get(cookieName)) {
      appID = Cookies.get(cookieName);
    } else {
      appID = "kyc-" + this.getGUID().replace(/-/g, "");
      let expDate = new Date();
      expDate.setFullYear(expDate.getFullYear() + 15);

      Cookies.set(cookieName, appID, {
        secure: import.meta.env.VITE_ISCUG == "true" ? true : "",
        sameSite: "strict",
      });
    }
    return appID;
  }

  metadataBuilder(event) {
    let data = JSON.stringify(event?.event_metadata);
    let RAW = JSON.parse(data);
    if (typeof RAW == "object" && RAW?.params) {
      delete RAW?.params;
    }
    if (event?.event_metadata?.params) {
      let meta = JSON.parse(this.dataBuilder(event?.event_metadata?.params));
      return JSON.stringify({ ...RAW, ...meta });
    } else if (event?.event_metadata?.backPopup) {
      let meta = {
        ScreenName: this.getScreenName(),
        Backtype: this.getDeviceName() + " " + "Back",
      };
      return JSON.stringify({ ...RAW, ...meta });
    } else if (event?.event_metadata?.arrParams) {
      let meta = this.objDataBuilder(event?.event_metadata?.arrParams);
      return JSON.stringify({ ...RAW, ...meta });
    } else {
      return event?.event_metadata;
    }
  }

  objDataBuilder(params) {
    const parsedBody = {};
    for (const key in params) {
      const value = params[key];
      let [keyParam, formDataKey] = value.split(":");
      parsedBody[key] = store.state.formData[formDataKey].includes(keyParam);
    }
    return parsedBody;
  }

  dataBuilder(params) {
    const parsedBody = {};
    for (const key in params) {
      const value = params[key];

      if (value.includes("|")) {
        const [field, dflt] = value.split("|");
        const fieldValue =
          typeof store.state.formData[field] != "undefined"
            ? store.state.formData[field]
            : dflt ?? "";
        if (fieldValue?.toString()) {
          parsedBody[key] = fieldValue;
        }
      } else {
        const fieldValue =
          typeof store.state.formData[value] != "undefined"
            ? store.state.formData[value]
            : "";
        if (fieldValue?.toString()) {
          parsedBody[key] = fieldValue;
        }
      }
    }
    return JSON.stringify(parsedBody);
  }
  getScreenName() {
    const state = store?.state;
    const screens = state?.layout?.screens;
    const routeKey = state?.activeRoute;
    const routeKeyPopUp = routeKey.split("/")[1] ?? routeKey;
    return screens[routeKeyPopUp].events?.onLoad?.cs?.bodyParameters
      ?.event_screen
      ? screens[routeKeyPopUp].events?.onLoad?.cs?.bodyParameters?.event_screen
      : screens[routeKeyPopUp];
  }
}

const clickStream = new ClickStreamAnalytics();

export { ClickStreamAnalytics, clickStream };
